import React from "react";

const LoginForm = () => {
  return (
    <div className="contact-form style-two">
      <form method="post" onSubmit={(e) => e.preventDefault()}>
        <div className="row">
          <div className="form-group col-md-12">
            <input
              type="email"
              className="form-control"
              name="email"
              defaultValue=""
              placeholder="Enter email address"
              required=""
            />
          </div>
          <div className="form-group col-md-12">
            <input
              type="password"
              className="form-control"
              name="form_password"
              defaultValue=""
              placeholder="Password"
              required=""
            />
          </div>
          <div className="form-group col-md-12">
            <input
              id="form_botcheck"
              name="form_botcheck"
              className="form-control"
              type="hidden"
              defaultValue=""
            />
            <button
              className="theme-btn btn-style-one"
              type="submit"
              data-loading-text="Please wait..."
            >
              <span>Log In</span>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
