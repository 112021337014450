import React from "react";
import LoginForm from "../src/components/login-form.component";
import Layouts from "../src/layouts/Layouts";
import Link from "next/link";

const Login = () => {
  return (
    <Layouts noHeader={true} noFooter={true} topBar={true}>
      <section className="login-page">
        <div className="auto-container">
          <div className="content-box">
            <div className="content-wrapper">
              <div className="row justify-content-center d-flex">
                <div className="col-lg-6">
                  <div className="login-block login-bg">
                    <div className="sub-title">Access your account</div>
                    <h2 className="sec-title mb-30">Log in</h2>
                    {/* form */}
                    <LoginForm />
                    {/* form end */}
                    <Link href="/signup">
                      <a className="login-link">
                        {" "}
                        Don't have an account yet? Sign Up
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layouts>
  );
};

export default Login;
